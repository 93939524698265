<template>
  <div>
    <v-row class="justify-center">
        <v-col v-for="opt in ComputedDashboardOptions" :key="opt.itemObjKey">
            <v-card height="200" class="siteconfigcard">
                <v-card-title class="mediumoverline">
                    {{opt.title}}<v-spacer>
                        </v-spacer>
                        <v-icon large>{{opt.Icon}}</v-icon>
                </v-card-title>
                <v-card-text class="caption" v-html="opt.Description">
                </v-card-text>
                <v-card-actions class="justify-end" style="position: absolute; bottom:10px;right: 20px;">
                    <v-spacer>
                    </v-spacer>
                    <v-btn dark @click="AddDashboard(opt)" :color="opt.Color">Add Now</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import SecurityOverview from '@/components/Database/SecurityOverview';
export default {
    props: ['System','SystemEntities','RADB','CategoricalNavlists'],
    components: {RAPluginCarousel,SecurityOverview},
    data() {
        return {
            DashboardOptions: [
                {title: 'Pipeline',Description: 'Build Pipelines from which users can interact. Requires Pipeline Plugin',Icon: 'mdi-pipe',Pluginid: 'Pipelines',RouteName: 'DashboardBuilder'},
                //{title: 'Quick Dashboard',Description: 'Build Quick Dashboards, yay. Requires Quick Dashboard Plugin',Icon: 'mdi-view-dashboard',Pluginid: 'Quick_Dashboard',RouteName: 'DashboardBuilder'},
                {title: 'Custom Dashboard',Description: 'Build Custom Dasboards, also "digital pivot tables". Requires Custom Dashboard Plugin',Icon: 'mdi-chart-box',Pluginid: 'Custom_Dashboard',RouteName: 'DashboardBuilder'},
            ],
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            SecurityView: false,
            ActiveEntity: '',
        }
    },	
    computed:{
        ComputedDashboardOptions(){
            return this.DashboardOptions.map(opt => {
                let applug = this.AppPlugins.find(obj => obj.id === opt.Pluginid)
                opt.Installed = false
                if(applug){
                    opt.Installed = true
                    opt.Color = 'success'
                }
                else{
                    opt.Color = 'warning'
                }
                return opt
            })
        },
       AppandSharedPlugins(){
            return this.AppPlugins.concat(
              this.ActiveSuiteApp.SharedPlugins .map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                return plugobj
            }).filter(plug => {
                return plug.Name
            }) 
            )
        },
        AppPlugins(){
            return this.ActiveSuiteApp.Plugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                return plugobj
            }).filter(plug => {
                return plug.Name
            })
        },
        RAPlugins(){
            return this.$store.state.RAPlugins
        },
        ActiveEntityTable(){
            if(this.ActiveEntity){
                let apptable = ''
                this.ActiveSuiteApp.Plugins.map(plug =>{
                    if(plug.Installation_Tables){
                        let table = plug.Installation_Tables.find(obj => obj.id === this.ActiveEntity.id)
                        apptable = {
                            DisplayName: table.DisplayName,
                            id: table.id,
                            Pluginid: plug.id
                        }
                        if(table.Extensions){
                            apptable.Extensions = table.Extensions
                        }
                    }
                })
                return apptable
            }
        },
        ActiveEntityPlugin(){
            if(this.ActiveEntityTable){
                return this.RAPlugins.find(obj => obj.id === this.ActiveEntityTable.Pluginid)
            }
        },
        ActiveEntityExtensions(){
            return this.ActiveEntityPlugin && this.ActiveEntityPlugin.Extensions? this.ActiveEntityPlugin.Extensions.map(ext => {
                let plugobj = this.RAPlugins.find(obj => obj.id === ext.id)
                return plugobj
            })  : []
        },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
            })
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        AddDashboard(opt){
            if(!opt.Installed){
                let plug = this.RAPlugins.find(obj => obj.id === opt.Pluginid)
                this.$emit('NewDashboardDialog',opt,true)
                this.$emit('PickPlugin',plug)
            }
            else{
                this.$emit('NewDashboardDialog',opt)
            }
        },
        PickPlugin(plug){
            this.$emit('PickPlugin',plug)
        },
        ToggleSecurityView(){
            this.SecurityView = !this.SecurityView
        },
        RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        })
        },
        ActivateActiveEntity(ent){
            this.ActiveEntity = ent
        },
    }
}
</script>

<style>

</style>



